import User01Icon from '@untitled-ui/icons-react/build/esm/User01';
import { Box, ButtonBase } from '@mui/material';
import { usePopover } from 'src/hooks/use-popover';
import { useAuth } from 'src/hooks/use-auth';
import UserAvatar from 'src/components/UserAvatar';
import { AccountPopover } from './account-popover';

export function AccountButton() {
  const {user} = useAuth();
  const popover = usePopover();

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: 'divider',
          height: 40,
          width: 40,
          borderRadius: '50%'
        }}
      >
        <UserAvatar name={user?.name || ""} />
      </Box>
      <AccountPopover
        anchorEl={popover.anchorRef.current}
        onClose={popover.handleClose}
        open={popover.open}
      />
    </>
  );
}
