import { Fragment, useCallback, useState } from "react";
import PropTypes from "prop-types";
import SearchMdIcon from "@untitled-ui/icons-react/build/esm/SearchMd";
import XIcon from "@untitled-ui/icons-react/build/esm/X";
import ArrowRightIcon from "@untitled-ui/icons-react/build/esm/ArrowRight";

import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import { searchApi } from "../../../api/search/SearchApi";
import { Tip } from "../../../components/tip";

export function SearchDialog(props) {
  const { onClose, open = false, ...other } = props;
  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [displayResults, setDisplayResults] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      setSearchResults([]);
      setDisplayResults(false);
      setIsLoading(true);
      // Do search here

      const results = await searchApi.getResults(value);
      setSearchResults(results);
      setIsLoading(false);
      setDisplayResults(true);
    },
    [value]
  );

  return (
    <Dialog fullWidth maxWidth="sm" onClose={onClose} open={open} {...other}>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={3}
        sx={{
          px: 3,
          py: 2,
        }}
      >
        <Typography variant="h6">Ricerca globale</Typography>
        <IconButton color="inherit" onClick={onClose}>
          <SvgIcon>
            <XIcon />
          </SvgIcon>
        </IconButton>
      </Stack>
      <DialogContent>
        <Tip message="Ricerca inserendo un termine e premendo invio" />
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon>
                    <SearchMdIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            label="Ricerca"
            onChange={(event) => setValue(event.target.value)}
            placeholder="Ricerca..."
            value={value}
          />
        </Box>
        {isLoading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 3,
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {displayResults && (
          <Stack spacing={2} sx={{ mt: 3 }}>
            {Object.keys(searchResults).map((type, index) => {
              if (searchResults[type].length === 0) {
                return <Fragment key={index} />;
              }

              return (
                <Stack key={index} spacing={2}>
                  <Typography variant="h6">{type}</Typography>
                  <Stack
                    divider={<Divider />}
                    sx={{
                      borderColor: "divider",
                      borderRadius: 1,
                      borderStyle: "solid",
                      borderWidth: 1,
                    }}
                  >
                    {searchResults[type].map((item, index) => (
                      <Box key={item.index} sx={{ p: 2 }}>
                        <Stack
                          alignItems="center"
                          direction="row"
                          spacing={2}
                          sx={{ pl: 1 }}
                        >
                          <Badge color="primary" variant="dot" />
                          <Typography variant="subtitle1">
                            {item.description}
                          </Typography>
                        </Stack>
                        <Typography color="text.secondary" variant="body2">
                          {item.link}
                        </Typography>
                        <Button
                          color="inherit"
                          endIcon={
                            <SvgIcon>
                              <ArrowRightIcon />
                            </SvgIcon>
                          }
                          size="small"
                          href={item.link}
                        >
                          Apri dettaglio
                        </Button>
                      </Box>
                    ))}
                  </Stack>
                </Stack>
              );
            })}
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
}

SearchDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
