import SearchMdIcon from "@untitled-ui/icons-react/build/esm/SearchMd";
import { IconButton, SvgIcon, Tooltip } from "@mui/material";
import { useDialog } from "../../../hooks/use-dialog";
import { SearchDialog } from "./search-dialog";

export function SearchButton() {
  const dialog = useDialog();

  return (
    <>
      <Tooltip title="Cerca">
        <IconButton onClick={dialog.handleOpen}>
          <SvgIcon>
            <SearchMdIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <SearchDialog onClose={dialog.handleClose} open={dialog.open} />
    </>
  );
}
