import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { Layout as DashboardLayout } from "../layouts/dashboard";

const Parameters = lazy(() =>
  import("../pages/administration/parameters/Parameters")
);

// Users
const UserDetail = lazy(() =>
  import("../pages/administration/users/UserDetail")
);
const UsersList = lazy(() => import("../pages/administration/users/UsersList"));
const ApplicationsList = lazy(() =>
  import("../pages/applications/ApplicationsList")
);

export const adminRoutes = [
  {
    path: "/administration",
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        path: "user",
        children: [
          {
            index: true,
            element: <UsersList />,
          },
          {
            path: ":userId",
            element: <UserDetail />,
          },
        ],
      },
      {
        path: "parameters",
        children: [
          {
            index: true,
            element: <Parameters />,
          },
        ],
      },
    ],
  },
  {
    path: "/application",
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        path: "",
        children: [
          {
            index: true,
            element: <ApplicationsList />,
          },
        ],
      },
    ],
  },
];
