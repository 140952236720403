function Mail04(props) {
  return <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path
      fill="currentColor"
      d="M10.256 13.3663 2 7.9999l8.256-5.3664c.6313-.4104.9469-.6155 1.287-.6953a1.9997 1.9997 0 0 1 .914 0c.3401.0798.6557.285 1.287.6953L22 7.9999l-8.256 5.3664c-.6313.4103-.9469.6155-1.287.6953a2.0014 2.0014 0 0 1-.914 0c-.3401-.0798-.6557-.285-1.287-.6953Z"
      opacity={0.12}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m13.744 2.6335 7.528 4.8932c.266.1729.399.2593.4954.3746a.9998.9998 0 0 1 .1884.3473C22 8.3924 22 8.551 22 8.8682v7.3317c0 1.6801 0 2.5202-.327 3.162a3 3 0 0 1-1.311 1.311c-.6418.327-1.4818.327-3.162.327H6.8c-1.6802 0-2.5202 0-3.162-.327a3.0002 3.0002 0 0 1-1.311-1.311C2 18.7201 2 17.88 2 16.1999V8.8682c0-.3173 0-.476.0442-.6196a1 1 0 0 1 .1885-.3473c.0963-.1153.2293-.2017.4953-.3746l7.528-4.8932m3.488 0c-.6313-.4104-.9469-.6155-1.287-.6953a1.9997 1.9997 0 0 0-.914 0c-.3401.0798-.6557.285-1.287.6953m3.488 0 7.2241 4.6956c.3439.2236.5159.3354.5754.4771a.4995.4995 0 0 1 0 .3874c-.0595.1417-.2315.2535-.5754.477l-7.2241 4.6957c-.6313.4103-.9469.6155-1.287.6953a2.0014 2.0014 0 0 1-.914 0c-.3401-.0798-.6557-.285-1.287-.6953L3.032 8.6706c-.344-.2235-.516-.3353-.5755-.477a.5.5 0 0 1 0-.3874c.0595-.1417.2315-.2535.5754-.477l7.2241-4.6957"
    />
  </svg>
}

export default Mail04;
