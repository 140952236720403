import axiosClient from "../../utils/axios";

class SearchApi {
  async getResults(searchString) {
    return new Promise((resolve, reject) => {
      try {
        axiosClient
          .post("search", {
            search: searchString,
          })
          .then((response) => {
            const results = response.data;

            resolve(results);
          })
          .catch((error) => {
            reject(new Error(error.response.data.message));
          });
      } catch (err) {
        console.error("[Search Api]: ", err);
        reject(new Error("Internal server error"));
      }
    });
  }
}

export const searchApi = new SearchApi();
