import axios from "axios";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { application } from "../config";
import { paths } from "../paths";

const axiosClient = axios.create();

axiosClient.defaults.baseURL = application.api.host_url;

axiosClient.defaults.headers = application.api.headers;

axiosClient.defaults.withCredentials = true;

// if (token) {
//   axiosClient.defaults.headers.common = { Authorization: `Bearer ${token}` };
// }

axiosClient.interceptors.request.use((config) => {
  const token = localStorage.getItem("accessToken");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

const AxiosInterceptor = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const resInterceptor = (response) => response;

    const errInterceptor = (error) => {
      /**
       * 401 ERROR
       */
      if (
        error.response.status === 401 &&
        localStorage.getItem("accessToken") !== null
      ) {
        console.log("401, logout");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("user");
        window.location.href = `${
          paths.auth.jwt.login
        }?returnTo=${encodeURIComponent(location.pathname)}`;
      }

      /**
       * 403 ERROR
       */
      if (error.response.status === 403) {
        console.log("403, unauthorized");
        enqueueSnackbar("Unauthorized", { variant: "warning" });
      }

      /**
       * 422 Error
       */
      if (error.response.status === 422) {
        console.log("422, Unprocessable Entity");

        const message = error.response.data.hasOwnProperty("message")
          ? error.response.data.message
          : "Unprocessable Entity";

        // let errors = "";
        // debugger;
        // for (const [key, value] of Object.entries(error.response.data.errors)) {
        //   errors += `${key}: ${value}`;
        // }

        console.warn(message, error.response.data.errors);

        enqueueSnackbar(message, { variant: "error" });
        // throw 'test';
        // return error.response;
      }

      /**
       * 404 Error
       */
      if (error.response.status === 404) {
        console.log("404");
        console.error(error.response);
        navigate("/404");
      }

      /**
       * 429 Error
       */
      if (error.response.status === 429) {
        console.log("429");
        navigate("/429");
      }

      /**
       * 500 ERROR
       */
      if (error.response.status === 500) {
        if (window.location.pathname.split("/").pop() !== "500") {
          console.log("500");
          console.error(error.response);
          navigate("/500");
        }
      }

      /**
       * 503 ERROR
       */
      if (error.response.status === 503) {
        console.log("503");
        console.error(error.response);
        window.location.reload();
      }

      return Promise.reject(error);
    };

    const responseInterceptor = axiosClient.interceptors.response.use(
      resInterceptor,
      errInterceptor
    );

    return () => {
      axiosClient.interceptors.response.eject(responseInterceptor);
    };
  }, [navigate]);

  return children;
};

export default axiosClient;
export { AxiosInterceptor };
