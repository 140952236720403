import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';

export function Seo(props) {
  const { title } = props;

  const fullTitle = title
    ? `${title  } | KHAI`
    : 'KHAI';

  return (
    <Helmet>
      <title>
        {fullTitle}
      </title>
    </Helmet>
  );
}

Seo.propTypes = {
  title: PropTypes.string
};
